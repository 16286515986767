.star {
  font-size: 2rem;
  color: #ccc;
  cursor: pointer;
}

.star.filled {
  color: gold;
}
.check{
  background-color: blue;
}